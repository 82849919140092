import UtilityHelper from '../utility-helper/utility-helper';
export default class AppDrawerElement extends HTMLElement {
  constructor() {
    super();
    this.opened = false;
    this._contentElement = null;
    this._enableSubmenu = false;
  }

  connectedCallback() {
    this._click = UtilityHelper.clickEvent;

    this._attachModalElement();

    this._setProperties();

    this._setMenuCloseEvent();

    setTimeout(() => {
      this._setContentElement();

      if (this._enableSubmenu) {
        this._setSubmenuElement();

        this._setSubmenuListeners();
      }
    }, 500);
  }

  _attachModalElement() {
    var node = document.createElement("div");
    node.classList.add('app-drawer-modal');
    this.appendChild(node);

    this._setDocumentCloseEvent(node);

    this._setSwipeEvent();
  }

  _setProperties() {
    var enableSubmenu = this.getAttribute('enable-submenu');
    if (enableSubmenu) this._enableSubmenu = true;
  }

  _setContentElement() {
    this._contentElement = this.querySelector('app-drawer-content');

    this._contentElement.addEventListener('transitionend', event => {
      if (!this.opened) {
        this.classList.remove('content-visible');

        this._contentElement.classList.remove('hide-main-drawer');
      }
    });
  }

  _setSubmenuElement() {
    this.submenuElement = this.querySelector('[data-drawer-submenu]');
  }

  _setDocumentCloseEvent(node) {
    $(node).on(this._click, event => {
      this.close();
    });
  }

  _setMenuCloseEvent() {
    var $appDrawer = $(this);
    $appDrawer.on(this._click, '[data-drawer-close-button]', this.close.bind(this));
  }

  _setSwipeEvent() {
    $(this).on('swipeleft', event => {
      this.close();
    });
  }

  _setSubmenuListeners() {
    var $appDrawer = $(this);
    $appDrawer.on(this._click, '[data-submenu-back-button]', this._onSubMenuBack.bind(this));
    $appDrawer.on(this._click, '[data-submenu-close-button]', this._onSubMenuClose.bind(this));
    $appDrawer.on(this._click, 'a[data-menu-id]', this._onSubMenuRequest.bind(this));
  }

  _onSubMenuRequest(event) {
    var target = event.target;
    var menuId = target.dataset.menuId;
    $(this).find('[data-menu]').removeClass('show');
    var menu = document.querySelector('[data-menu="' + menuId + '"]');
    menu.classList.add('show');
    this.subMenuOpen = true;

    this._contentElement.classList.add('hide-main-drawer');

    this.submenuElement.classList.add('show');
  }

  _onSubMenuBack(event) {
    this._contentElement.classList.remove('hide-main-drawer');
  }

  _onSubMenuClose(event) {
    this.submenuElement.classList.add('hide-menu');
    this.close();
  }

  open() {
    if (!this.opened) {
      this.opened = true;
      this.classList.add('open', 'content-visible');
      document.body.classList.add('body-overflow-hidden');
    }
  }

  openSubMenu(id) {
    if (!this.opened) {
      $(this).find('[data-menu]').removeClass('show');
      var menu = document.querySelector('[data-menu="' + id + '"]');
      menu.classList.add('show');
      this.subMenuOpen = true;

      this._contentElement.classList.add('hide-main-drawer');

      this.submenuElement.classList.add('show-no-animation');
      this.opened = true;
      this.classList.add('open', 'content-visible');
      document.body.classList.add('body-overflow-hidden');
    }
  }

  close() {
    if (this.opened) {
      this.opened = false;
      this.classList.remove('open');
      document.body.classList.remove('body-overflow-hidden');

      if (this._enableSubmenu) {
        this.submenuElement.classList.remove('show');
        this.submenuElement.classList.remove('show-no-animation');
      }
    }
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

}
customElements.define('app-drawer', AppDrawerElement);