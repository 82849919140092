export default class LightGalleryElement extends HTMLElement {
  constructor() {
    super();
    this.pluginCreated = false;
  }

  connectedCallback() {}

  _initialize() {}

  _createPlugin(index, data) {
    $(this).lightGallery({
      dynamic: true,
      dynamicEl: data,
      index: index
    });
  }

  get jsonData() {
    if (this._json) return this._json;
    var data = this.getAttribute('json-data');

    if (data) {
      var json = JSON.parse(data);
      this._json = json;
      return json;
    } else {
      return null;
    }
  }

  show(index) {
    if (index === undefined) index = 0;
    var data = this.jsonData;

    if (!this.pluginCreated) {
      this.pluginCreated = true;

      this._createPlugin(index, data);
    } else {
      this.destroy(index);

      this._createPlugin(index, data);
    }
  }

  destroy(index) {
    var lightGallery = $(this).data('lightGallery');
    lightGallery.index = index;
    lightGallery.s.index = index;
    console.log(lightGallery.index);
  }

}
customElements.define('light-gallery', LightGalleryElement);