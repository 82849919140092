import UtilityHelper from '../utility-helper/utility-helper';
import './form-notification';
export default class FormApiSubmitElement extends HTMLElement {
  constructor() {
    super();
    this.form = null;
    this.$form = null;
    this.notification = null;
    this._handleNotification = false;
    this.active = true;
    this.action = null;
    this.method = null;
    this.mobile = false;
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;
    this.mobile = UtilityHelper.isMediaTouch;
    setTimeout(() => {
      this._setProperties();

      this._setEvents();
    }, 750);
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _setProperties() {
    this.form = this.querySelector('form');
    this.$form = $(this.form);

    if (!this.form) {
      this.active = false;
      console.log('Error: FORM-API-SUBMIT requires a child form element.');
      return;
    }

    this.action = this.form.getAttribute('action');

    if (!this.action) {
      this.active = false;
      console.log('Error: FORM-API-SUBMIT requires a child form element with an action attribute set.');
      return;
    }

    this.method = this.form.getAttribute('method');
    if (!this.method) this.method = 'post';
    this._handleNotification = this.handleNotification;

    if (this._handleNotification) {
      this.notification = this.querySelector('form-notification');
    }
  }

  _setEvents() {
    if (!this.active) return;
    var form = this.form;
    form.addEventListener('submit', this._onSubmit.bind(this));
  }

  _removeEvents() {
    var form = this.form;

    if (form) {
      form.removeEventListener('submit', this._onSubmit.bind(this));
    }
  }

  _onSubmit(event) {
    event.preventDefault();
    var data = this.$form.document();

    this._nativeXHRSubmit(data);
  }

  _nativeXHRSubmit(data) {
    var err = null;
    var formDataResult = null;
    var method = this.method;
    var action = this.action;
    var formData = data;
    formData = JSON.stringify(formData);
    var request = new XMLHttpRequest();
    request.open(method, action, true);
    request.setRequestHeader('Content-Type', 'application/json');

    request.onload = event => {
      if (request.status === 200) {
        formDataResult = JSON.parse(request.response);
      } else {
        try {
          err = JSON.parse(request.response);
          if (!err.message) err = {
            statusCode: request.status,
            message: request.statusText
          };
        } catch (ex) {
          err = {
            statusCode: request.status,
            message: request.statusText
          };
        }
      }

      this._onResponse(err, formDataResult);
    };

    request.send(formData);
  }

  _onResponse(err, result) {
    if (err) this._onError(err);else this._onSuccess(result);
  }

  _onSuccess(result) {
    var message = this.successMessage;
    var toast = this.toast;

    if (this._handleNotification && this.mobile) {
      if (toast) {
        this._showToast(message);
      } else if (this.notification) {
        this._showNotification('success', message);
      }
    } else if (this.handleNotification) {
      if (this.notification) {
        this._showNotification('success', message);
      } else if (toast) {
        this._showToast(message);
      }
    }

    this._fireEvent('success', null, result);

    var redirect = this.redirectOnSuccess;

    if (redirect) {
      this._redirect(redirect);
    }
  }

  _onError(err) {
    var message = this.errorMessage(err);
    var toast = this.toast;

    if (this._handleNotification && this.mobile) {
      if (this.toast) {
        this._showToast(message);
      } else if (this.notification) {
        this._showNotification('error', message);
      }
    } else if (this.handleNotification) {
      if (this.notification) {
        this._showNotification('error', message);
      } else if (toast) {
        this._showToast(message);
      }
    }

    this._fireEvent('error', err, null);
  }

  _showNotification(type, message) {
    this.notification.show(type, message, null, null);
  }

  _showToast(message) {
    this.toast.show(message);
  }

  _redirect(url) {
    var delay = this.redirectDelay;
    setTimeout(() => {
      location.href = url;
    }, delay);
  }

  _fireEvent(type, err, data) {
    this.fire('form-api-submit-' + type, {
      data: data,
      err: err,
      form: this.form,
      target: this
    });
  }

  get handleNotification() {
    var value = this.getAttribute('handle-notification');
    if (value && value !== 'false') return true;else return false;
  }

  get successMessage() {
    var message = this.getAttribute('success-message');
    if (!message) return 'The form was successfully submitted.';else return message;
  }

  errorMessage(err) {
    var message = this.getAttribute('error-message');
    if (err.message) return 'Error: ' + err.message;else if (message) return message;else return 'Form submission error';
  }

  get toast() {
    return document.querySelector('paper-toast');
  }

  get redirectOnSuccess() {
    return this.getAttribute('redirect-on-success');
  }

  get redirectDelay() {
    var strDelay = this.getAttribute('redirect-delay');
    if (strDelay) return parseInt(strDelay);else return 750;
  }

}
customElements.define('form-api-submit', FormApiSubmitElement);